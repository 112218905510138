@import './src/variables';
.contactInfo {
  margin: 0 auto;
  opacity: 0;
  padding: 10px 0 20px 0px;
  font-size: 14px;
  color: #9c9c9c;
  & > div {
    text-align: right;
  }
  a{
    color: $hafsynBlue;
    text-decoration: none;
  }
}
