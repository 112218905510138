@import './src/variables';

.ownerDetail {
  width: 600px;
  margin: 0 auto;
  padding: 30px 0 30px;
  background: $bodyBg;
  border: 1px solid $borderC;
  border-radius: 0 0 3px 3px;
  h1{
    text-align: center;
  }
  .vesselList{
    padding: 0 20px;
    background: #fff;
    > div {
      border: 1px solid $borderC;
      margin-bottom: 20px;
    }
    .trackwellExpandable-title  {
      padding: 20px;
      &.open{
        padding: 20px 20px 0px 20px;
      }
      & > span{
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .loading {
    display: grid;
    margin:0 auto;
    position: relative;
    width: 107px;
    height: 50px;
    animation: fadein 1s;
    padding-bottom: 30px;
  }
  .loading div {
    position: absolute;
    width: 32px;
    background: $hafsynBlue;
    animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .loading div:nth-child(1) {
    animation-delay: -0.24s;
  }
  .loading div:nth-child(2) {
    left: 37px;
    animation-delay: -0.12s;
  }
  .loading div:nth-child(3) {
    left: 74px;
    animation-delay: 0;
  }

  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  @keyframes loading {
    0% {
      top: 0px;
      height: 50px;
    }
    50%, 100% {
      top: 10px;
      height: 35px;
    }
  }

}
