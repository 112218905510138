@import './src/variables';

.noOwner {
  width: 600px;
  margin: 0 auto;
  padding: 30px 0 60px;
  background: $bodyBg;
  border: 1px solid $borderC;
  border-radius: 0 0 3px 3px;
  h1{
    text-align: center;
  }
  > .inputs{
    width: 345px;
    margin: 0 auto;
    position: relative;
    .trackwellInput.text{
      margin-bottom: 23px;
      &.error{
        margin-bottom: 0;
      }
    }
    input{
      width: 225px;
      box-sizing: border-box;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    button{
      height: 35px;
      align-self: end;
      border-radius: 0;
      border: 0;
      background: $hafsynBlue;
      color: #fff;

      position: absolute;
      top: 26px;
      right: 0px;
      &:hover, &:focus{
        background: lighten($hafsynBlue, 10%);
      }
      &:active{
        background: darken($hafsynBlue, 5%);
      }
    }
  }

  .missingEmail{
    display: block;
    text-align: center;
    padding: 20px 0;
    color: #f5921d;
    font-size: 18px;
  }


  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }

}

