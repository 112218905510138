@import '/src/variables.scss';

iframe {
  pointer-events: none;
}

html{
  background: $htmlBg;
}

body {
  background-color: $bodyBg;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#body, #header > div{
  width: 1200px;
  margin: 0 auto;
}

#body, #entry{
  background: $htmlBg;
}

#header{
  background: $bodyBg;
  box-sizing: border-box;
  height: 80px;
  padding: 15px 0;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 40px;
  & > div{
    height: 100%;
    img{
      height: 100%;
    }
  }
}

.questionMark{
  padding: 0 !important;
}

.trackwellTable-title{
  text-align: center;
  & > .trackwellTable-options{
    display: none;
  }
}

.trackwellButton{
  border-radius: 6px !important;
}

.trackwellInput-select-option{
  width: 100%;
}

@media (max-width: 1200px) {
  #body, #header > div{
    width: 1024px;
  }

}
@media (max-width: 992px) {
  #body, #header > div{
    width: 768px;
  }
}
@media (max-width: 768px) {
  #body, #header > div{
    width: calc(100% - 20px);
    margin: 0 auto;
  }
}
