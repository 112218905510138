@import './src/variables';
.vesselDetail {
  padding: 10px 0 20px 20px;
  background: #f8f9fd;
  .header{
    font-weight: 600;
    padding-bottom: 10px;
    display: block;
  }
  .yearFee{
    .header{
      > span{
        position: absolute;
        margin-left: -5px;
      }
    }
    span{
      display: inline-block;
      padding-right: 10px;
    }
  }
  .list{
    .captain{
      border: 1px solid #E5E5E5;
      margin-bottom: 15px;
      padding-bottom: 10px;
      margin-right: 20px;
      border-radius: 4px;
      overflow: hidden;
      .name{
        padding: 5px;
        padding-left: 15px;
        position: relative;
        height: 20px;
        background: #ececec;
        .unassign{
          position: absolute;
          top: 0;
          right: -4px;
          padding: 0 10px;
          min-width: unset;
          text-transform: capitalize;
          transform: scale(0.75);
        }
      }
      a{
        text-decoration: none;
      }
    }
  }

  .phoneNumbers{
    background: #fff;
    padding-left: 20px;
    padding-top: 10px;
    a{
      color: #45bccc;
    }
    & > div {
      padding-bottom: 5px;
    }
    button{
      display: block;
      padding: 0 10px;
      margin-top: 5px;
      transform: scale(0.75);
      transform-origin: left;
    }
  }

}
.unassignDialogText{
  text-align: center;
  font-size: 18px;
}

.trackwellDialog{
  --theme-button_text: #ffffff;
  --theme-button_background: #34363f;
  --theme-button_border_color: #979797;
  --theme-button_disabled: #595b64;
  --theme-button_disabled_text: #414349;
  --theme-button_outline: #34363f;
  --theme-button_outline_hover: #34363f;
  --theme-button_outline_active: #28292;
}

.trackwellDialog-confirm{

}

