@import './src/variables';
.addCaptainDialog {
  width: 320px;
  .inputs{
    display: grid;
    grid-template-columns: 150px 150px;
    grid-column-gap: 20px;
    grid-row-gap: 15px;

    input, .mockInput{
      box-sizing: border-box;
    }
    > :nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    > :nth-child(2) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
  .trackwellExpandable-dropdown-content{
    font-size: 14px;
    margin-top: 4px;
    color: var(--theme-input_validationColor);
  }
  .submit{
    margin-top: 20px;
    display: grid;
    button{
      justify-self: end;
    }
  }
}
