@import './src/variables';

.topNoToken{
  display: grid;
  grid-template-columns: 300px auto;
  background: $bodyBg;
  padding: 30px;
  background: #F9F9F9;
  border: 1px solid #E5E5E5;
  border-radius: 0 0 3px 3px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
    transform: scale(0.75);
    transform-origin: bottom;
  }

  @media (max-width: 700px) {
    transform: scale(1);
    grid-template-columns: auto;
    width: calc(100% - 80px) !important;
    justify-content: center;
    margin-left: 10px;
  }

  img{
    display: block;
    width: 300px;
    align-self: center;
    justify-self: center;
  }
  h1,h2,h3{
    display: block;
  }

  @media (max-width: 768px) {
    grid-template-columns: auto;
    width: calc(100% - 20px);
    justify-content: center;
  }

}

.noToken {
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;

  > div {
    padding: 30px 0 60px;
    background: $bodyBg;
    border: 1px solid $borderC;
    border-radius: 0 0 3px 3px;
  }

  .iskey{
    min-width: 400px;
    padding-bottom:20px;
    h3{
      text-align: center;
      padding: 0 20px;
    }
    .iskey-login{
      margin: 0 auto;
      background: $hafsynBlue;
      height: 45px;
      width: 225px;
      box-sizing: border-box;
      color: white;
      display: block;
      padding-left: 25px;
      position: relative;
      line-height: 45px;
      text-decoration: none;
      margin-top: 40px;
      margin-bottom: 20px;
      &:hover{
        background: darken($hafsynBlue, 10%);
      }
      img {
        position: absolute;
        top: -63%;
        right: -38px;
        width: 60px;
      }
    }
    .iskey-info{
      display: block;
      text-align: center;
      padding: 20px 0;
      color: $iskeyBlue;
    }
    .iskey-get{
      display: block;
      text-align: center;
      color: gray;
    }
    .iskey-disclaimer{
      display: block;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    .iskey-privacy{
      display: block;
      text-align: center;
      text-decoration: none;
    }
  }

  .appBox{
    padding: 30px;
    .appLinks{
      display: grid;
      max-width: 500px;
      grid-template-columns: auto auto;
      grid-column-gap: 30px;
      margin-top: 30px;
      img{
        width: 100%;
        height: auto;
        min-height: 120px;
      }
    }
  }

  @media (max-width: 768px) {
    width: calc(100% - 20px);
    .iskey{
      transform: scale(0.75);
      transform-origin: top right;
    }
    .appBox{
      transform: scale(0.75);
      transform-origin: top left;
    }
  }

  @media (max-width: 700px) {
    grid-row-gap: 10px;
    h3{
      font-size: 15px;
    }
    .iskey{
      transform: scale(1);
      width: 100%;
      min-width: unset;

    }
    .appBox{
      transform: scale(1);
      width: 100%;
      min-width: unset;
      max-width: calc(100% - 60px);
    }
    grid-template-columns: auto;

  }

}
