@import './src/variables';
.phoneNumberDialog {
  width: 300px;
  .inputs{
    display: grid;
    grid-template-columns: 130px auto;
    grid-column-gap: 20px;
    width: 300px;

    .text{
      width: 150px;
    }

    input, .mockInput{
      box-sizing: border-box;
    }
  }
  .trackwellExpandable-dropdown-content{
    font-size: 14px;
    margin-top: 4px;
    color: var(--theme-input_validationColor);
  }
  .submit{
    margin-top: 20px;
    display: grid;
    button{
      justify-self: end;
    }
  }
}
